<template>
  <div class="stepper">
    <numberbox
      :default-value="num"
      @stepChange="valChange"
      :step="1"
      :min="min"
      :max="max"
      :disabled="disabled"
    >
      <img slot="minus" :src="require('@STATIC/image/icons/subtract.png')" />
      <img slot="plus" :src="require('@STATIC/image/icons/add.png')" />
    </numberbox>
  </div>
</template>

<script>
import numberbox from "@C/numberBox/numberBoxV2.vue";
export default {
  name: "stepperV1",
  components: {
    numberbox,
  },
  props: {
    defaultValue: {
      type: Number,
      default: 1,
    },
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: 100,
    },
    params: null,
    disabled:{
      type:Boolean,
      default:false
    }
  },
  data() {
    return {
      num: 0,
    };
  },
  created() {
    this.num = this.$props.defaultValue;
  },
  watch: {
    defaultValue(newV) {
      this.num = newV;
    },
  },
  mounted() {},
  methods: {
    valChange(value) {
      if(!this.$props.disabled){
        this.$emit("input", value);
        this.$emit("onChange",{value:value,params:this.params});
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.stepper {
  display: inline-flex;
}
img {
  width: 16px;
  height: 16px;
}
</style>
