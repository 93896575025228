<template>
  <div class="numberbox">
    <div
        @click.stop.prevent="changeInputVal('minus')"
        :class="minusClass"
        v-if="!disabled"
    >
      <slot name="minus">-</slot>
    </div>

    <template v-if="disabled">
      <div class="numberbox_input" style="font-size: 16px;width: 30px">× {{inputVal}}</div>
    </template>
    <template v-else>
      <input
          v-model="inputVal"
          @blur="onBlur"
          @click.stop.prevent="onClick"
          type="number"
          style="width: 30px;"
          class="numberbox_input"
          :disabled="disabled"
      />
    </template>
    <div
        v-if="!disabled"
        @click.stop.prevent="changeInputVal('plus')"
        :class="plusClass"
    >
      <slot name="plus">+</slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "numberBoxV2.vue",
  props: {
    minusClass: {
      type: Array,
      default() {
        return ['numberbox_minus']
      },
    },
    inputClass: {
      type: Array,
      default() {
        return ['numberbox_input']
      },
    },
    plusClass: {
      type: Array,
      default() {
        return ['numberbox_plus']
      },
    },
    disabled: {
      type: Boolean,
      default: false
    },
    // 预显示的数字
    defaultValue: {
      type: Number,
      default: 1,
    },
    // 最小值
    min: {
      type: Number,
      default: 0,
    },
    // 最大值
    max: {
      type: Number,
      default: 99999,
    },
  },
  data() {
    return {
      inputVal: 0
    }
  },
  mounted() {
    this.inputVal = this.$props.defaultValue
  },
  methods: {
    onBlur(e) {
      let number = e.target.value
      let min = parseInt(this.$props.min)
      let max = parseInt(this.$props.max)

      let inputVal = number
      if (inputVal <= min) {
        inputVal = min
      } else if (inputVal >= max) {
        inputVal = max
      }

      Object.assign(this, {
        inputVal
      })

      this.$emit('stepChange',inputVal)
    },
    onClick() {

    },
    changeInputVal(type = 'minus') {
      if(this.$props.disabled){
        return
      }
      let inputVal = JSON.parse(JSON.stringify(this.inputVal))
      if(type == 'minus'){
        inputVal--
      }else{
        inputVal++
      }
      let min = parseInt(this.$props.min)
      let max = parseInt(this.$props.max)

      if (inputVal <= min) {
        inputVal = min
      } else if (inputVal >= max) {
        inputVal = max
      }

      Object.assign(this, {
        inputVal
      })

      this.$emit('stepChange',inputVal)
    }
  }
}
</script>

<style scoped lang="scss">
@include b(numberbox) {
  display: inline-flex;
  align-items: center;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  @include e(minus) {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
  }
  @include e(input) {
    margin: 0 4px;
    border: none;
    background-color: transparent;
    width: 20px;
    text-align: center;
  }
  @include e(plus) {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
  }
}
</style>